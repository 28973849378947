import React, { useEffect, useState } from 'react';
import {
  Page,
  Navbar,
  Block,
   Button,
  Segmented,
  Link,
  NavTitle,
  NavLeft,
  List,
  ListItem,
  Icon, useStore,
} from 'framework7-react';
import store from '../store';

const PhrasePracticePage = (props) => {
  const { f7router } = props; // Truy cập f7router từ props

  const [activeTab, setActiveTab] = useState('practice');
  const phrasesToPractice = useStore('phrasesToPractice');
  const learnedPhrases = useStore('learnedPhrases');
  const searchSentences = useStore('searchSentences');
useEffect(()=>{
  if(phrasesToPractice === null || phrasesToPractice.length === 0){
    store?.dispatch('loadCarts');
  }
  if(learnedPhrases === null || learnedPhrases.length === 0){
    store?.dispatch('loadLearned');
  }
},[phrasesToPractice,learnedPhrases])
 /*  const phrasesToPractice = [
    'Make a decision',
    'Take responsibility',
    'Get along with',
    'Run out of time',
    'Look forward to',
    'Pay attention to',
    'Go out of your way',
    'The quick brown fox jumps over the lazy dog',
    'A piece of good advice',
    'Keep in touch',
  ]; */

 /*  const learnedPhrases = [
    'Break the ice',
    'Bite the bullet',
    'Cut corners',
    'Hit the sack',
    'Let the cat out of the bag',
  ]; */

  const addedPhrases = [
    'Speak of the devil',
    'Burn the midnight oil',
    'Kill two birds with one stone',
    'Once in a blue moon',
    'The ball is in your court',
  ];

  const handleSegmentedButtonClick = (tab) => {
    setActiveTab(tab);
    tab === 'learned'? store?.dispatch('loadLearned'):  store?.dispatch('loadCarts');
  };

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = () => {
    console.log('Searching for:', searchQuery);
    store?.dispatch('searchSentence',{text:searchQuery, activeTab });

  };

  return (
    <Page className="phrase-practice-page">
      {/* Navbar */}
      {/* <Navbar sliding
        className='header-orange'
        title="Cụm từ của bạn"
        backLink={<i className="ny icon-back-icon" />}
      /> */}

      <Navbar
        className='header-orange'>
        <NavLeft>
          <Link onClick={() => f7router.back()}><i className="ny icon-back-icon" />Thống kê</Link>
        </NavLeft>
        <NavTitle>Cụm từ của bạn</NavTitle>
      </Navbar>

      {/* Tabs Header */}
      <Block className="tabs-header">
        <Segmented strong tag="p">
          <Button active={activeTab === 'practice'} onClick={() => handleSegmentedButtonClick('practice')}>
            Cần luyện tập
          </Button>
          <Button active={activeTab === 'learned'} onClick={() => handleSegmentedButtonClick('learned')}>
            Đã học
          </Button>
         {/*  <Button active={activeTab === 'added'} onClick={() => handleSegmentedButtonClick('added')}>
            Đã thêm
          </Button> */}
        </Segmented>

      </Block>
      {/* Search Block */}
      <Block className="search-block">
        <div className="search-container">
          <i className="ny icon-MagnifyingGlass-icon search-icon"></i>
          <input
            type="text"
            placeholder=""
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
        </div> <Button fill onClick={handleSearch} className="search-button">
          Tìm kiếm
        </Button>
      </Block>
      {/* Results Block (Optional) */}
      <Block className='result-search'>
      5 Cụm từ được tìm thấy
      </Block>

      {/* Tabs Content */}
      <Block className="tab-content">
        <div className='swap'>
        <div className={`tab-content-item ${activeTab === 'practice' ? 'active' : 'inactive-right'}`}>
          <List>
            {phrasesToPractice.map((phrase, index) => (
              <ListItem
                key={index}
                title={phrase.selected_answer}
                after={<Icon ios="f7:info_circle" md="material:info_outline" />}
              />
            ))}
          </List>
        </div>

        <div className={`tab-content-item ${activeTab === 'learned' ? 'active' : 'inactive-left'}`}>
          <List>
            {learnedPhrases.length > 0 ? (
              learnedPhrases.map((phrase, index) => (
                <ListItem
                  key={index}
                  title={phrase.selected_answer}
                  after={<Icon ios="f7:info_circle" md="material:info_outline" />}
                />
              ))
            ) : (
              <Block>Chưa có cụm từ đã học.</Block>
            )}
          </List>
        </div>

       {/*  <div className={`tab-content-item ${activeTab === 'added' ? 'active' : 'inactive-left'}`}>
          <List>
            {addedPhrases.length > 0 ? (
              addedPhrases.map((phrase, index) => (
                <ListItem
                  key={index}
                  title={phrase}
                  after={<Icon ios="f7:info_circle" md="material:info_outline" />}
                />
              ))
            ) : (
              <Block>Chưa có cụm từ đã thêm.</Block>
            )}
          </List>
        </div> */}
        </div>
      </Block>

    
    </Page>
  );
};

export default PhrasePracticePage;
