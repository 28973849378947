import React, { useState, useRef } from 'react';
import {
    Page,
    Block,
    Button,
    Link,
    Navbar,
    NavLeft,
    NavRight,
    f7,
} from 'framework7-react';

import myLogo from '../img/Wordbank.svg';
import myBar from '../img/barcode.png';
import iconCaret from '../img/caret-down.svg';
import imgChange from '../img/chance.png';
import imgShare from '../img/share-link.png';
import imgGift from '../img/gift-box.png';
import imgTouchphone from '../img/touch-phone.png';
import imgShare2 from '../img/share-link-2.png';
import imgShare3 from '../img/share-link-3.png';
import imgFaq from '../img/faq.png';
import imgWhitelogo from '../img/white-logo.svg';



const SharingLandingPage = () => {

    const [isContentVisible, setIsContentVisible] = useState(false);
    const contentRef = useRef(null);

    const handleToggleContent = () => {
        setIsContentVisible((prevState) => !prevState);
    };

    const scrollToContent = () => {
        if (contentRef.current) {
            contentRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    const handleClick = () => {
        handleToggleContent();
        setTimeout(() => {
            scrollToContent();
        }, 100);
    };



    return (
        <Page className="sharing-landing-page">
            <Navbar className='tran-head'>
                <NavLeft>
                    <Link onClick={() => f7.views.main.router.back()}>
                        <div className="heart">
                            <span className="heart-count">Back</span>
                        </div>
                    </Link>
                </NavLeft>
                <NavRight>
                    <Link className="get-likes-button">
                        <i className="f7-icons" style={{ fontSize: '24px', color: 'white' }}>globe</i>
                    </Link>
                </NavRight>
            </Navbar>
            {/* Header Section */}
            <section className="header-section">
                <Block className="logo">
                    <img src={myLogo} alt="Logo" />
                </Block>
                <Block className="title">
                    <p>Học Tiếng Anh Miễn Phí <br></br>
                        Nhận Thưởng Hấp Dẫn</p>
                </Block>
                <Block className="subtitle">
                    <p>Tham gia ngay để nhận 10 điểm học thử và cơ hội nhận <br></br>
                        quà với giải thưởng lên đến <span className='text-sp'>10.000$</span></p>
                </Block>
                <Block className='swapct'>
                    <Block className='bar-code'> <img src={myBar} alt="barcode" style={{ width: '100%' }} /></Block></Block>
                <Block className="subtitle">
                    <p>Scan QR Code to get link</p>
                </Block>
                {/* Registration Form Section */}
                <Block className="register-form-section">
                    <div className='swap-btn'><Link className='btn-orange-small'>Nâng cấp ngay <i className="ny icon-heart-icon" />
                    </Link></div>
                </Block>

                <Link className="btnLearnmore" onClick={handleClick} style={{ marginTop: '5rem' }}>
                    <div className="learnmore">Tìm hiểu thêm</div>
                    <div className="icon">
                        <img src={iconCaret} alt="Down" />
                    </div>
                </Link>

            </section>
            {isContentVisible && (
                <div ref={contentRef} className='showmore'>
                    {/* Why You Can't Speak English Section */}
                    <section className='whycanspeak'>
                        <div className="cannottalk">Khám Phá Cơ Hội <br></br>
                            Học Tập Và Nhận Quà</div>
                        <div className="anyway">Chúng tôi rất vui mừng chào đón bạn đến với cộng đồng học tiếng Anh năng động. Wordbank không chỉ là nơi để bạn cải thiện kỹ năng ngoại ngữ mà còn mang đến những phần thưởng hấp dẫn khi bạn tham gia các chương trình ưu đãi đặc biệt.</div>
                        <div className='swap-img'><img src={imgChange} alt="chance" style={{ width: '100%' }} /></div>
                    </section>

                    {/* Solution Section */}


                    {/* Main Features Section */}
                    <Block className="main-features">
                        <h3>Chương trình 1:<br></br>
                            Học Miễn Phí <br></br>
                            Chỉ Cần Chia Sẻ</h3>
                        <p><span>Nhận 21 điểm học thử:</span><br></br> Đăng ký tài khoản mới, bạn nhận ngay 21 điểm miễn phí để bắt đầu học.</p>
                        <p><span>Kiếm điểm miễn phí bằng cách chia sẻ:</span><br></br> Chia sẻ link đăng ký cá nhân, mỗi lượt đăng ký thành công qua link của bạn sẽ mang lại thêm 10 điểm miễn phí.</p>
                        <p><span>Lợi ích:</span><br></br>Học tiếng Anh hoàn toàn miễn phí chỉ bằng cách chia sẻ ứng dụng với bạn bè.</p>
                        <div className='swap-img'><img src={imgShare} alt="chance" style={{ width: '100%' }} /></div>
                        <h3>Chương trình 2:<br></br>
                            Quay Số Nhận Thưởng <br></br>Khi Mua Gói Học</h3>
                        <p><span>Mua 1,000 điểm học với giá 20 USD:</span><br></br> Bạn sẽ tham gia vào chương trình hộp quà may mắn</p>
                        <p><span>Mỗi lượt giới thiệu “free” mới:</span><br></br>Bạn sẽ nhận được ngay 10đ học và 1 hộp quà may mắn.</p>
                        <p><span>Giải thưởng giá trị:</span><br></br>Hộp quà may mắn với giải thưởng từ 1 USD đến 10,000 USD, với tổng giá trị giải thưởng lên tới
                            1.000.000 USD tiền marketing!</p>

                        <div className='swap-img'><img src={imgGift} alt="chance" style={{ width: '100%' }} /></div>
                        <p><span>Lợi ích:<br></br>
                            Bạn không chỉ có thể tiếp tục học tiếng Anh mà
                            còn có cơ hội trúng các giải thưởng giá trị, giúp bạn thêm động lực trong hành trình học tập.
                        </span></p>

                        <div className='swap-btn'><Link className='btn-grey-big'>Copy & Share to get 10 <i className="ny icon-heart-icon" /></Link></div>


                    </Block>

                    {/*   Section */}
                    <Block className="funtion">
                        <h3>Cách thức <br></br>
                            tham gia rất đơn giản</h3>
                        <img src={imgTouchphone} alt="ADV" style={{ width: '100%' }} />
                        <h3>1: Đăng ký tài khoản mới</h3>
                        <p>Nhận ngay 21 điểm học thử để
                            bắt đầu hành trình học tập.</p>
                        <img src={imgShare2} alt="ADV" style={{ width: '100%', marginBottom: '2rem', marginTop: '2rem' }} />
                        <h3>2. Chia sẻ link cá nhân:</h3>
                        <p>Mỗi lượt đăng ký thành công qua link
                            của bạn sẽ mang lại 21 điểm miễn phí.</p>
                        <img src={imgShare3} alt="ADV" style={{ width: '100%' }} />

                        <h3>3. Mua gói học:</h3>
                        <p>Chỉ với 20 USD, bạn vừa có thêm 1,000 điểm để học, vừa nhận được tham gia quay xổ số với các giải thưởng giá trị.</p>
                        <div className='swap-btn'><Link className='btn-orange-big'>Copy & Share to get 10 <i className="ny icon-heart-icon" /></Link></div>
                    </Block>

                    {/* Hearing Section */}
                    <Block className="main-features">
                        <h3>Học Tiếng Anh Chưa <br></br>
                            Bao Giờ Thú Vị Đến Thế</h3>
                        <div className="group-box">
                            <div className="group">
                                <ul className='learn-box'>
                                    <li>
                                        <h2 className="hoc-text">Trải nghiệm học tập thú vị:</h2>
                                        <p className='ct-kv'>Nhận điểm học thử và bắt đầu cải thiện khả năng giao tiếp tiếng Anh.</p>
                                    </li>

                                    <li>
                                        <h2 className="hoc-text">Cơ hội nhận thưởng lớn:</h2>
                                        <p className='ct-kv'>Giải thưởng hấp dẫn lên đến 10,000 USD.</p>
                                    </li>

                                    <li>
                                        <h2 className="hoc-text">Đơn giản và dễ tham gia:</h2>
                                        <p className='ct-kv'>Chỉ cần đăng ký và chia sẻ link để tích lũy điểm miễn phí.</p>
                                    </li>
                                </ul>

                            </div>
                        </div>

                        <h3 style={{ marginTop: '4rem' }}>Câu Hỏi <br></br>
                            Thường Gặp</h3>
                        <img src={imgFaq} alt="ADV" style={{ width: '100%' }} />
                        <ul className='faq'>
                            <li className='quest'>Câu Hỏi 1:<br></br>
                                Tôi đã học tiếng Anh nhiều năm nhưng không
                                nói được, Wordbank có phù hợp không? </li>
                            <li className='answer'>
                                Hoàn toàn phù hợp! Wordbank giúp bạn biến lý
                                thuyết thành kỹ năng giao tiếp thực tế.</li>

                            <li className='quest'>Câu Hỏi 2: <br></br>
                                Tôi có thể luyện tập ở bất kỳ lĩnh vực nào không?</li>
                            <li className='answer'>
                                Có! Bạn chỉ cần nhập từ vựng, AI sẽ tạo bài tập
                                phù hợp cho lĩnh vực bạn chọn.</li>


                            <li className='quest'>Câu Hỏi 3: <br></br>
                                Mất bao lâu để thấy hiệu quả? </li>
                            <li className='answer'>
                                Với 15-30 phút luyện tập mỗi ngày, bạn sẽ thấy rõ
                                sự tiến bộ sau 21 ngày.</li>
                        </ul>
                        <div className='swap-btn' style={{ marginBottom: '4rem', marginTop: '2rem' }}><Link className='btn-grey-big'>Copy & Share to get 10 <i className="ny icon-heart-icon" /></Link></div>
                    </Block>

                    {/* Footer Section */}
                    <Block className='curve'></Block>
                    <Block className="curved-background">
                        <div className="content">
                            <img src={imgWhitelogo} alt="logo" style={{ width: '200px' }} />
                            <p>Try the new way to learn language absolutely free</p>
                            <div className='swap-btn' style={{ marginTop: '3rem', marginBottom: '3rem' }}><Link className='btn-orange-big'>Copy & Share to get 10 <i className="ny icon-heart-icon" /></Link></div>
                            <footer>
                                <Link>Wordbank 2024</Link>
                                <Link>Privacy policy</Link>
                                <Link>Terms of use</Link>
                            </footer>
                        </div>
                    </Block>
                </div>
            )}
        </Page>
    );
};

export default SharingLandingPage;
