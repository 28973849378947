import { createStore } from 'framework7/lite';
import { f7 } from 'framework7-react';
import { post, get, setAuthToken } from './utils/api-helper';
const store = createStore({
  state: {
    user: null,
    usersLoading: false,
    speaking: false,
    quiz: null,
    phrasesToPractice: [],
    learnedPhrases: [],
    searchSentences: [],
    lang: 'vi',
    translated: '',
    score: 0,
    error: '',
    referral: ''
  },
  actions: {
    setLoading({ state }, isLoading) {
      state.isLoading = isLoading;
    },
    setReferralCode({ state }, code) {
      state.referral = code;
    },
    loadUsers({ state }) {
      state.usersLoading = true;
      setTimeout(() => {
        state.usersLoading = false;
        state.users = ['Aaron', 'Alexander', 'Candy', 'Chloe', 'Vladimir'];
      }, 3000);
    },
    async loadQuiz({ state }, id = 0) {
      state.usersLoading = true;
      try {
        const response = await post(`/quizzes/${id}`, null);
        state.quiz = response.data;
        localStorage.setItem('data-quiz', JSON.stringify(response.data))
      } catch (error) {
        state.error = error;
      }
      state.usersLoading = false;

    },
    async loadNextQuiz({ state, dispatch }, { id = null, f7router }) {
      if (state.user) {
        localStorage.setItem('pre-quiz', JSON.stringify(state.quiz))
        dispatch('loadQuiz', id)
      }
      else {
        f7router.navigate('/login-page/')
      }
    },
    async loadPreQuiz({ state }) {
      const pre = localStorage.getItem('pre-quiz')
      const q = pre && JSON.parse(pre);
      if (q) state.quiz = q;

    },
    async loadCarts({ state }) {
      state.usersLoading = true;
      try {
        const response = await get(`/user-quiz-answers/selected-answer`);
        if (response.data)
          state.phrasesToPractice = response.data;
        else
          state.error = response.message
      } catch (error) {
        state.error = error;
      }

      state.usersLoading = false;

    },
    async loadLearned({ state }) {
      state.usersLoading = true;
      try {
        const response = await get(`/user-quiz-answers/learned`);
        if (response.data)
          state.learnedPhrases = response.data;
        else
          state.error = response.message
      } catch (error) {
        state.error = error;
      }

      state.usersLoading = false;

    },
    async searchSentence({ state }, { text, activeTab }) {
      state.usersLoading = true;
      try {
        const response = await get(`sentences?search=${text}&status=5&lang=vi`);
        if (response.data) {
          let data = []
          if (response.data.data) {
            data = response.data.data.map(m => {
              return {
                id: m.id,
                sentence_id: m.id,
                selected_answer: m.content,
              }
            })
          }

          activeTab === 'learned' ? state.learnedPhrases = data :
            state.phrasesToPractice = data;
        }
        else
          state.error = response.message
      } catch (error) {
        state.error = error;
      }

      state.usersLoading = false;

    },
    async loadTranslate({ state }) {
      //state.usersLoading = true;
      try {
        const res = await get(`sentences/translate/${state.quiz?.sentence_id}/${state.lang}`);
        if (res.data) {
          state.translated = res.data.translated_text
        }
      } catch (error) {
        console.log("🚀 ~ fetchData ~ error:", error)
        state.translated = state.quiz && state.quiz.content

      }

      //state.usersLoading = false;

    },
    handleSpeech({ state }, { rate = 0.9, onCompleted, text }) {
      const msg = new SpeechSynthesisUtterance();
      const voices = window.speechSynthesis.getVoices();
      msg.text = String(text || state?.quiz?.content).toLowerCase()
      msg.lang = "en-US";
      msg.rate = rate || 0.9;
      msg.pitch = 1;
      msg.volume = 1;

      // Use a dynamic fallback if the preferred voice isn't available
      const voice = voices.find((v) => v.name === 'Samantha');
      if (voice) {
        msg.voice = voice;
      }
      msg.lang = "en-US";

      msg.onstart = () => { state.speaking = true };
      msg.onend = () => { state.speaking = false; onCompleted?.(); };

      // Check if speech synthesis is supported
      if ('speechSynthesis' in window) {
        window.speechSynthesis.speak(msg);
      } else {
        console.error("Speech synthesis is not supported in this browser.");
      }
    },
    async logout({ state }) {
      debugger
      state.user = null;
      sessionStorage.removeItem("authUser");
      f7.views.main.router.navigate('/login-page/')
    },
    async loginUser({ state }, { user, f7router, onLoginCompleted }) {
      state.usersLoading = true;
      try {
        let response;
        response = await post(`/auth/login`, {
          email: user.email,
          password: user.password,
        })
        var data = response.results;
        if (data) {
          sessionStorage.setItem("authUser", JSON.stringify(data));
          setAuthToken(data.token_value)
          state.user = data
          state.score = data.score;
          if (user.isModal) {
            onLoginCompleted(null)
          }
          else {
            f7router.navigate('/fill-the-form/')
          }
        }
      } catch (error) {
        const errorMessage = (error && typeof error === 'object') ? JSON.stringify(error) : String(error);

        // Gọi hàm onLoginCompleted và chuyển thông báo lỗi
        onLoginCompleted?.(errorMessage);

        // Cập nhật state với lỗi
        state.error = errorMessage;
      }
      state.usersLoading = false;
    },
    async registerUser({ state }, { user, f7router, onLoginCompleted }) {
      state.usersLoading = true;
      try {
        let response;
        let payload = { email: user.email, fullname: user.email }
        if (state.referral) {
          payload.referral = state.referral;
        }
        response = await post(`/auth/register`, payload)
        var data = response.results;
        onLoginCompleted(null)
        if (data) {
          console.log("🚀 ~ registerUser ~ data:", data)
          //sessionStorage.setItem("authUser", JSON.stringify(data));
          //state.user = data
          f7router.navigate('/login-page/')
        }
      } catch (error) {
        const errorMessage = (error && typeof error === 'object') ? JSON.stringify(error) : String(error);

        // Gọi hàm onLoginCompleted và chuyển thông báo lỗi
        onLoginCompleted?.(errorMessage);

        // Cập nhật state với lỗi
        state.error = errorMessage;
      }
      state.usersLoading = false;
    },
    async handleSubmitOption({ state }, answer) {

      if (!state.user) {
        return false
      }
      if (state.score && state.score < 0) {
        //toast('You do not have enough points to continue.')
        return;
      }

      let type = 1;


      const data = {
        user_id: state.user?.id,
        quiz_id: state.quiz?.id,
        question_index: [answer.optionIndex],
        selected_answer: [answer.answerText],
        type,
      };
      try {
        const res = await post(`/user-quiz-answers`, data);
        state.score = state.score + res.data.total_score_change
      } catch (error) {
        state.error = error
      }
    }
  },
  getters: {
    usersLoading: ({ state }) => state.usersLoading,
    users: ({ state }) => state.users,
    score: ({ state }) => state.score,
    referral: ({ state }) => state.referral,
    user: ({ state }) => {
      if (!state.user) {
        const authUser = sessionStorage.getItem("authUser")
        const data = authUser && JSON.parse(authUser);
        if (data) {
          setAuthToken(data.token_value)
          state.user = data
          state.score = data.score;
        }


      }
      return state.user
    },
    currentQuiz: ({ state }) => {
      if (!state.quiz) {
        const t = localStorage.getItem('data-quiz')
        if (t) {
          const q = JSON.parse(t)
          state.quiz = q;
        }
      }
      return state.quiz
    },
    currentTranslate: ({ state }) => state.translated,
    browserSpeaking: ({ state }) => state.speaking,
    phrasesToPractice: ({ state }) => state?.phrasesToPractice,
    learnedPhrases: ({ state }) => state?.learnedPhrases,
    searchSentences: ({ state }) => state?.searchSentences,
    link: ({ state }) => {
      const baseURL = window.location.origin;
      const referral = state.user?.id ? `share=${state.user.id}` : '';
      const constructedLink = `${baseURL}?${referral}`;

      return constructedLink;
    }

  },
});

export default store;
