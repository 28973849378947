import React, { useState } from 'react';
import {
    Page,
    Navbar,
    Block,
    Link,
    NavLeft,
    NavRight
} from 'framework7-react';

import myChecked from '../img/check-item.svg';

const PaymentWithdraw = ({ f7router }) => {

    const data = [
        { label: 'Mạng lưới', value: 'Eth', isHighlight: true },
        { label: 'Địa chỉ', value: 'KLKSHJskjdlaksjdaKLHJDLASKLKJSksdjfjkshdlksad',  isCopyable: true  },
        { label: 'ID giao dịch', value: '983742ksjdfhas83902392ksdfshjdfsdiousd934sdojfsldjkflsjdfsjdfjlsksdofsdokfposd', isCopyable: true },
        { label: 'Số tiền rút', value: '500 USDT', isCt: true },
        { label: 'Phí mạng lưới', value: '1,2 USDT', isCt: true  },
        { label: 'Ngày giờ', value: '2024-04-08 09:00', isCt: true  },
    ];

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        alert('Copied to clipboard!');
    };

    return (
        <Page className="payment-page">
            {/* Navbar */}
            <Navbar
                className='header-orange'>
                <NavLeft>
                    <Link onClick={() => f7router.back()}><i className="ny icon-back-icon" />Withdrawal</Link>
                </NavLeft>
                <NavRight>
                    <Link className="get-info-btn">
                        <span className="get-money">100$</span>
                        <i className="ny icon-hand-get-icon" style={{ fontSize: '1.4rem' }} />
                    </Link>
                </NavRight>
            </Navbar>

            {/* Order and Promo Code Section */}
            <Block className="order-detail">
                <div className="qr-code-block">
                    <p style={{
                        color: "#666666",
                        textAlign: "center",
                        fontFamily: '"Oswald", sans-serif',
                        fontSize: "25px",
                    }}>Thông tin rút</p>
                    <p style={{
                        color: "#666666",
                        textAlign: "center",
                        fontFamily: '"Oswald", sans-serif',
                        fontSize: "28px",
                    }}>500,00 USDT  </p>
                    <p style={{
                        color: "#90d92b",
                        textAlign: "center",
                        fontFamily: '"Roboto", sans-serif',
                        fontSize: "15px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}> <img style={{ width: '15px', marginRight: '5px' }} src={myChecked} alt='img' className='img' />Completed</p>
                    <p style={{
                        color: "#666666",
                        textAlign: "center",
                        fontFamily: '"Roboto", sans-serif',
                        fontSize: "15px",
                    }}>Đã chuyển tiền mã hóa ra khỏi Wordbank.</p>

                    <table style={{ width: '100%', borderCollapse: 'collapse', fontFamily: 'Arial, sans-serif', fontSize: '14px', color: '#555' }}>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index} style={{ borderBottom: 'none', padding: '10px 0' }}>
                                    {/* Cột Label */}
                                    <td style={{ padding: '8px 10px', fontWeight: 500, textAlign: 'left', width: '30%', verticalAlign:'text-top' }}>
                                        {item.label}
                                    </td>
                                    {/* Cột Value */}
                                    <td style={{ padding: '8px 10px', textAlign: 'right', width: '70%', display:'flex', justifyContent:'flex-end', width:'94%' }}>
                                        <div
                                            style={{
                                                display: item.isCt ? '' : 'flex',
                                                alignItems: 'flex-start',
                                                gap: '8px',
                                                wordBreak: 'break-word',
                                                width:'',
                                                padding: item.isHighlight ? '2px 8px' : '0',
                                                borderRadius: item.isHighlight ? '4px' : '0',
                                                background:  item.isHighlight ? 'rgba(211, 217, 43, 0.42)' : 'transparent',
                                                borderRadius: item.isHighlight ? '5px' : '0',
                                                boxShadow: item.isHighlight ? '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' : 'none',
                                            }}
                                        >
                                            <span>{item.value}</span>
                                            {item.isCopyable && (
                                                <Link onClick={() => handleCopy(item.value)} style={{ cursor: 'pointer', marginTop:'4px' }}>
                                                    <i className="ny icon-copy-icon" />
                                                </Link>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>                   
                 
                </div>


            </Block>

        </Page>
    );
};

export default PaymentWithdraw;
