import React, { useState } from 'react';
import {
  Page,
  Navbar,
  Block,
  Button,
  Card,
  Link,
  NavTitle,
  NavLeft,
  NavRight,
} from 'framework7-react';
import Select from 'react-select';

const HuntGiftPage = ({ f7router }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const options = [

    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
  ];

  const customStyles = {
    control: (base) => ({
      ...base,
      minWidth: '50px',
      fontSize: '14px',
    }),
    menu: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    option: (base, { isFocused }) => ({
      ...base,
      backgroundColor: isFocused ? '#eee' : 'white',
      color: '#333',
      padding: '10px',
    }),
    container: (provided) => ({
      ...provided,
      width: '100px',
    }),
  };

  // Dữ liệu mẫu (40 items)
  const data = Array.from({ length: 40 }, (_, index) => ({
    id: index + 1,
    email: `user${index + 1}@example.com`,
    reward: index % 2 === 0 ? '🎁' : `${Math.floor(Math.random() * 100)}$`,
    date: `12/1/2024`,
    points: Math.floor(Math.random() * 100),
  }));

  const totalPages = Math.ceil(data.length / rowsPerPage);

  const currentData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (direction) => {
    if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (direction === 'next' && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleRowsPerPageChange = (selectedOption) => {
    setRowsPerPage(selectedOption.value);
    setCurrentPage(1);
  };

  return (
    <Page className="hunt-gift-page">
      <Navbar className="header-orange">
        <NavLeft>
          <Link onClick={() => f7router.back()}>
            <i className="ny icon-back-icon" />
            Member
          </Link>
        </NavLeft>
        <NavTitle>Hunt Gift</NavTitle>
        <NavRight>
          <Link className="get-info-btn">
            <span className="get-money">100$</span>
            <i className="ny icon-hand-get-icon" style={{ fontSize: '1.4rem' }} />
          </Link>
        </NavRight>
      </Navbar>
      {/* Search Block */}
      <Block className="search-block">
        <div className="search-container">
          <i className="ny icon-MagnifyingGlass-icon search-icon"></i>
          <input
            type="text"
            placeholder=""
            className="search-input"
          />
        </div> <Button fill className="search-button">
          Tìm kiếm
        </Button>
      </Block>
      {/* Results Block (Optional) */}
      <Block className='result-search'>
        5 Cụm từ được tìm thấy
      </Block>
      <Block className="tab-content">
        <Card className="data-table" style={{ overflow: 'hidden' }}>
          <div style={{ overflowY: 'auto', maxHeight: '74vh' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead style={{ position: 'sticky', top: 0, background: '#f7f7f7', zIndex: 2 }}>
                <tr>
                  <th style={{ padding: '10px', textAlign: 'left', width: '10%' }}>Stt</th>
                  <th style={{ padding: '10px', textAlign: 'left', width: '40%' }}>Email</th>
                  <th style={{ padding: '10px', textAlign: 'center', width: '15%' }}>Quà</th>
                  <th style={{ padding: '10px', textAlign: 'center', width: '20%' }}>Ngày đăng ký</th>
                  <th style={{ padding: '10px', textAlign: 'right', width: '15%' }}>Số điểm</th>
                </tr>
              </thead>
              <tbody>
                {currentData.map((row) => (
                  <tr key={row.id}>
                    <td style={{ padding: '10px', width: '10%', background: "#D9D9D9" }}>{row.id}</td>
                    <td style={{ padding: '10px', textAlign: 'left', width: '40%' }}>{row.email}</td>
                    <td style={{ padding: '10px', textAlign: 'center', width: '15%' }}>{row.reward}</td>
                    <td style={{ padding: '10px', textAlign: 'center', width: '20%' }}>{row.date}</td>
                    <td style={{ padding: '10px', textAlign: 'right', width: '15%' }}>{row.points}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
            marginTop: '10px',
          }}
        >
          <div>
            <Select
              id="rows-per-page"
              options={options}
              defaultValue={options[0]}
              onChange={handleRowsPerPageChange}
              menuPlacement="top"
              styles={customStyles}
            />
          </div>

          <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center' }}>
            <span style={{ display: 'flex', flexWrap: 'nowrap', justifyContent:'center', alignItems:'center' }}>
              Trang {currentPage} / {totalPages}
            </span>
            <div style={{ display: 'flex', gap: '5px' }}>
              <Link
                onClick={() => handlePageChange('prev')}
                className={`button ${currentPage === 1 ? 'disabled' : ''}`}
              >
               <i className="ny icon-back-caret-icon" />

              </Link>
              <Link
                onClick={() => handlePageChange('next')}
                className={`button ${currentPage === totalPages ? 'disabled' : ''}`}
              >
               <i style={{transform: 'rotate(180deg)'}} className="ny icon-back-caret-icon" />

              </Link>
            </div>
          </div>


        </div>
      </Block>
    </Page>
  );
};

export default HuntGiftPage;
