import React, { useState } from 'react';
import {
  Page,
  Navbar,
  Block,
  Button,
  Link,
  NavLeft,
  NavRight,
  Segmented,
} from 'framework7-react';
import Select from 'react-select';
import myEthe from '../img/ethe.png';

const WithDrawPage = ({ f7router }) => {
  const [activeTab, setActiveTab] = useState('usdt');

  const handleSegmentedButtonClick = (tab) => setActiveTab(tab);

  const options = [
    {
      value: 'Ethereum',
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={myEthe} alt="Ethereum" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
          Ethereum
        </div>
      ),
    },
    {
      value: 'BTC',
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={myEthe} alt="BTC" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
          BTC
        </div>
      ),
    },
  ];

  const customStyles = {
    control: (base) => ({
      ...base,
      minWidth: '160px',
      border: 'none',
      borderRadius: '8px',
      padding: '5px',
      boxShadow: 'none',
      '&:hover': { borderColor: '#666' },
    }),
    menu: (base) => ({
      ...base,
      borderRadius: '8px',
      marginTop: '0',
    }),
    option: (base, { isFocused }) => ({
      ...base,
      backgroundColor: isFocused ? '#eee' : 'white',
      color: '#333',
      padding: '10px',
    }),
  };

  const customComponents = {
    IndicatorSeparator: () => null,
  };

  return (
    <Page className="top-up-page with-draw">
      <Navbar className="header-orange">
        <NavLeft>
          <Link onClick={() => f7router.back()}>
            <i className="ny icon-back-icon" />
            Rút tiền
          </Link>
        </NavLeft>
        <NavRight>
          <Link className="get-info-btn">
            <span className="get-money">100$</span>
            <i className="ny icon-hand-get-icon" style={{ fontSize: '1.4rem' }} />
          </Link>
        </NavRight>
      </Navbar>

      <Block className="order-block">
        <Block className="tabs-header">
          <Segmented strong tag="p">
            <Button active={activeTab === 'usdt'} onClick={() => handleSegmentedButtonClick('usdt')}>
              USDT
            </Button>
            <Button active={activeTab === 'bank'} onClick={() => handleSegmentedButtonClick('bank')}>
              Ngân hàng
            </Button>
          </Segmented>
        </Block>
        <div className='content-segment'>
          {/* USDT Content */}
          {activeTab === 'usdt' && (
            <div className='content-segment'>
              <Block className='swap-ct'>
                <div className="header-topup">Rút tiền</div>
                <Block className="register-form-section" style={{ zIndex: '999' }}>
                  <Block className='swap-form'>
                    <label>Địa chỉ</label>
                    <Select
                      options={options}
                      components={customComponents}
                      styles={customStyles}
                      placeholder="Select"
                      className='list-input custom-dropdown '
                    />

                  </Block>
                  <div className="form-container">
                    <input
                      type="email"
                      className="email-input"
                      placeholder="Nhập ví thanh toán"
                    />
                  </div>
                </Block>
                <Block className="register-form-section">
                  <Block className='swap-form'>
                    <label style={{ height: '30px' }}>Số tiền rút</label>
                  </Block>
                  <div className="form-container">
                    <input
                      type="email"
                      className="email-input"
                      placeholder="Min 5$"
                    />
                    <div className="submit-button">
                      USDT
                      <Button fill className='white-btn'>
                        Max
                      </Button>
                    </div>

                  </div>
                </Block>
                <div className='swap-texx'>
                  <div class="txt1">Có sẵn</div>
                  <div class="txt2">100 USDT</div>
                </div>
              </Block>
              <Block className="back-home-button-block">
                <div className='bar-line'></div>
                <div>
                  <table style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                    textAlign: 'left',
                    marginBottom: '10px'
                  }}>
                    <tbody>
                      <tr>
                        <td style={{ padding: '5px' }}>Phí mạng lưới</td>
                        <td style={{ padding: '5px', textAlign: 'right' }}>1 USDT</td>
                      </tr>
                      <tr>
                        <td style={{ padding: '5px' }}>Số tiền nhận được</td>
                        <td style={{ padding: '5px', textAlign: 'right' }}>- USDT</td>
                      </tr>
                      <tr>
                        <td style={{ padding: '5px' }}>Tổng</td>
                        <td style={{ padding: '5px', textAlign: 'right' }}>10 USDT</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Link className="back-home-button">Rút</Link>
              </Block>
            </div>
          )}

          {/* Bank Content */}
          {activeTab === 'bank' && (
            <div className='content-segment'>
              <Block className='swap-ct'>
                <div className="header-topup">Rút tiền</div>
                <Block style={{ zIndex: '9' }} className="register-form-section">

                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <tbody>
                      <tr>
                        <td style={{ width: '50%', padding: '5px 0', verticalAlign: 'middle', color: '#666', fontWeight: '500' }}>
                          Tên ngân hàng
                        </td>
                        <td style={{ width: '50%', padding: '5px 0' }}>
                          <input
                            type="text"
                            placeholder="Nhập tên ngân hàng"
                            style={{
                              width: '100%',
                              borderRadius: "5px",
                              borderStyle: "solid",
                              borderColor: "rgba(174, 171, 171, 0.26)",
                              borderWidth: "1px",
                              padding: '5px',
                              boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)"
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: '50%', padding: '5px 0', verticalAlign: 'middle', color: '#666', fontWeight: '500' }}>
                          Tên chủ tài khoản
                        </td>
                        <td style={{ width: '50%', padding: '5px 0' }}>
                          <input
                            type="text"
                            placeholder="Nhập tên chủ tài khoản"
                            style={{
                              width: '100%',
                              borderRadius: "5px",
                              borderStyle: "solid",
                              borderColor: "rgba(174, 171, 171, 0.26)",
                              borderWidth: "1px",
                              padding: '5px',
                              boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)"
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: '50%', padding: '5px 0', verticalAlign: 'middle', color: '#666', fontWeight: '500' }}>
                          Số tài khoản ngân hàng
                        </td>
                        <td style={{ width: '50%', padding: '5px 0' }}>
                          <input
                            type="text"
                            placeholder="Nhập số tài khoản"
                            style={{
                              width: '100%',
                              borderRadius: "5px",
                              borderStyle: "solid",
                              borderColor: "rgba(174, 171, 171, 0.26)",
                              borderWidth: "1px",
                              padding: '5px',
                              boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)"
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Block>
                <Block className="register-form-section">
                  <Block className='swap-form'>
                    <label style={{ height: '30px', color: "#666666", fontSize: "15px" }}>Số tiền rút</label>
                  </Block>
                  <div className="form-container">
                    <input
                      type="text"
                      className="email-input"
                      placeholder="Min 100.000 VNĐ"
                    />
                    <div className="submit-button">
                      VND
                      <Button fill className='white-btn'>
                        Max
                      </Button>
                    </div>
                  </div>
                </Block>
                <div className='swap-texx'>
                  <div className="txt1">Có sẵn</div>
                  <div className="txt2">10,000,000 VNĐ</div>
                </div>
                <div style={{ marginTop: '10px', color: "#666666", fontSize: "15px", fontWeight: 500 }}>Mã giao dịch: Xhanxchuxklsd</div>
            
              </Block>

              <Block className="back-home-button-block">
                  <div className='bar-line'></div>
                  <div>
                    <table style={{
                      width: '100%',
                      borderCollapse: 'collapse',
                      textAlign: 'left',
                      marginBottom: '10px'
                    }}>
                      <tbody>
                        <tr>
                          <td style={{ padding: '5px' }}>Phí mạng lưới</td>
                          <td style={{ padding: '5px', textAlign: 'right' }}>1 USDT</td>
                        </tr>
                        <tr>
                          <td style={{ padding: '5px' }}>Số tiền nhận được</td>
                          <td style={{ padding: '5px', textAlign: 'right' }}>- USDT</td>
                        </tr>
                        <tr>
                          <td style={{ padding: '5px' }}>Tổng</td>
                          <td style={{ padding: '5px', textAlign: 'right' }}>10 USDT</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <Link className="back-home-button">Rút</Link>
                </Block>

            </div>

          )}
        </div>
      </Block>
    </Page>
  );
};

export default WithDrawPage;
