import React, { useState, useRef } from 'react';
import {
    Page,
    Block,
    Button,
    Link,
    Navbar,
    NavLeft,
    NavRight,
    f7,
} from 'framework7-react';

import myLogo from '../img/Wordbank.svg';
import iconCaret from '../img/caret-down.svg';
import imgAc from '../img/abc_as.png';
import imgMobile from '../img/mobile-ui.png';
import imgManmic from '../img/man-mic.png';
import img61 from '../img/6_1.png';
import imgWinman from '../img/win-main.png';
import imgSimple from '../img/simple.png';
import imgBrain from '../img/brain.png';
import imgMicvoice from '../img/mic-voice.png';
import imgBook from '../img/book.png';
import imgFaq from '../img/faq.png';
import imgWhitelogo from '../img/white-logo.svg';



const RegisterLandingPage = () => {

    const [isContentVisible, setIsContentVisible] = useState(false);
    const contentRef = useRef(null);

    const handleToggleContent = () => {
        setIsContentVisible((prevState) => !prevState);
    };

    const scrollToContent = () => {
        if (contentRef.current) {
            contentRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    const handleClick = () => {
        handleToggleContent();
        setTimeout(() => {
            scrollToContent();
        }, 100);
    };



    return (
        <Page className="register-landing-page">

            <Navbar className='tran-head' large transparent sliding={false} mdCenterTitle>
                <NavLeft>
                    <Link onClick={() => f7.views.main.router.back()}>
                        <div className="heart">
                            <span className="heart-count">Back</span>
                        </div>
                    </Link>
                </NavLeft>
                <NavRight>
                    <Link className="get-likes-button">
                        <i className="f7-icons" style={{ fontSize: '24px', color: 'white' }}>globe</i>
                    </Link>
                </NavRight>
            </Navbar>
            {/* Header Section */}
            <section className="header-section">
                <Block className="logo">
                    <img src={myLogo} alt="Logo" />
                </Block>
                <Block className="title">
                    <p>Cải thiện tiếng anh giao tiếp sau 21 ngày!</p>
                </Block>
                <Block className="subtitle">
                    <p>Đồng hành cùng bạn chinh phục giao tiếp<br></br>
                        tiếng Anh thông qua học cụm từ và hình ảnh ngữ cảnh.</p>
                </Block>

                {/* Registration Form Section */}
                <Block className="register-form-section">
                    <Block className="text-reg">
                        <p>Already have an account?<a href='/login-page/'>Sign in</a></p>
                    </Block>
                    <div className="form-container">
                        <input
                            type="email"
                            className="email-input"
                            placeholder="Email address"
                        />
                        <Button fill className="sign-up-button">
                            Sign up
                        </Button>
                    </div>
                </Block>
                <Block className="end-text">
                    <p>Hệ thống sẽ giử mật khẩu về Email của bạn.<br></br>
                        Nếu không thấy vui lòng kiểm tra trong mục “Spam”.</p>
                </Block>
                <Link className="btnLearnmore" onClick={handleClick}>
                    <div className="learnmore">Tìm hiểu thêm</div>
                    <div className="icon">
                        <img src={iconCaret} alt="Down" />
                    </div>
                </Link>

            </section>
            {isContentVisible && (
                <div ref={contentRef} className='showmore'>
                    {/* Why You Can't Speak English Section */}
                    <section className='whycanspeak'>
                        <img src={imgAc} alt="ADV" style={{ width: '100%' }} />
                        <div className="cannottalk">Bạn Đã Học Tiếng Anh Nhưng Vẫn Không Thể Nói Được?</div>
                        <div className="anyway">Dù bạn đã học từ vựng và ngữ pháp, nhưng khi giao tiếp, bạn vẫn cảm thấy ngập ngừng và thiếu tự tin. <br></br>Vì sao?</div>
                        <Block className="why-not">

                            <div className="group-box">
                                <div className="group">
                                    <ul className='learn-box'>
                                        <li>
                                            <h2 className="hoc-text">Học từ vựng, ngữ pháp rời rạc:</h2>
                                            <p className='ct-kv'>Bạn biết từ, hiểu cấu trúc, nhưng khi cần ghép chúng thành câu hoàn chỉnh, bạn mất quá nhiều thời gian suy nghĩ.</p>
                                        </li>
                                        <li className='no-line'><div className='spec-to'></div></li>
                                        <li>
                                            <h2 className="hoc-text">Không quen với cụm từ thông dụng:</h2>
                                            <p className='ct-kv'>Người bản ngữ giao tiếp bằng cụm từ tự nhiên, nhưng bạn lại cố gắng dịch từng từ một, khiến câu nói thiếu tự nhiên và không trôi chảy.</p>
                                        </li>
                                        <li className='no-line'><div className='spec-to'></div></li>
                                        <li>
                                            <h2 className="hoc-text">Thiếu môi trường luyện tập thực tế:</h2>
                                            <p className='ct-kv'><b>Ngại nói, sợ sai.
                                                Không ai sửa lỗi:</b> Bạn không biết mình nói đúng hay sai, dẫn đến việc lặp lại lỗi.</p>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <div className='result'>
                                <h2>Hậu Quả Là Gì?</h2>
                                <ul className="custom-list">
                                    <li><span>Hiểu nhưng không phản xạ được:</span>
                                        <p>Bạn mất thời gian để ghép câu, làm chậm cuộc trò chuyện.</p></li>
                                    <li><span>Mất tự tin:</span>
                                        <p>Ngại giao tiếp trong các tình huống thực tế như phỏng vấn, họp hành, hoặc trò chuyện hàng ngày.</p></li>

                                </ul>
                            </div>
                        </Block></section>

                    {/* Solution Section */}
                    <Block className="solution">
                        <h3>Giải Pháp <br></br>Wordbank Đồng<br></br> Hành Cùng Bạn</h3>
                        <p>
                            Chúng tôi không chỉ bán sản phẩm, mà còn đồng hành cùng bạn. Với phương pháp học cụm từ theo ngữ cảnh và công nghệ AI cá nhân hóa, bạn sẽ chinh phục giao tiếp tiếng Anh dễ dàng.
                        </p>
                        <img src={imgMobile} alt="ADV" style={{ width: '100%' }} />
                        <div className='swap-btn'><Link className='btn-orange-big'>Nâng cấp ngay</Link></div>
                    </Block>

                    {/* Main Features Section */}
                    <Block className="main-features">
                        <h3>Chỉ Sau 21 Ngày,<br></br> Bạn Sẽ Nhận Được</h3>
                        <div className='relative'>
                            <img src={imgManmic} alt="ADV" style={{ width: '100%' }} />
                            <div className='absolute'>
                                <h3>Phản xạ nhanh,<br></br>
                                    nói tự tin</h3>
                                <p>Không còn dịch trong đầu,<br></br>
                                    phản xạ ngay bằng tiếng Anh.</p></div>
                        </div>
                        <div className='swap-btn'><Link className='btn-grey-big'>Nâng cấp ngay</Link></div>
                        <div className='relative' style={{ width: '100%', marginTop: '4rem' }}>
                            <img src={img61} alt="ADV" style={{ width: '100%' }} />
                            <div className='absolute' style={{ top: '1rem' }} >
                                <h3>Nhớ lâu hơn <br></br>
                                    với hình ảnh ngữ cảnh</h3>
                                <p>Học từ vựng và cụm từ <br></br>
                                    qua hình ảnh giúp ghi nhớ lâu hơn.</p></div>
                        </div>
                        <img className='right-img' src={imgWinman} alt="ADV" style={{ width: '100%', marginTop: '2rem' }} />
                        <div className='swap-btn'><Link className='btn-grey-big'>Nâng cấp ngay</Link></div>

                    </Block>

                    {/* funtion Section */}
                    <Block className="funtion">
                        <h3>Tính Năng Chính <br></br>
                            Của Wordbank</h3>
                        <img src={imgSimple} alt="ADV" style={{ width: '100%' }} />
                        <h3>Học Cụm Từ <br></br>
                            Qua Hình Ảnh</h3>
                        <p>Nhiệm vụ của bạn là sắp xếp các cụm câu thành 1 câu hoàn chỉnh dựa vào hình ảnh cung cấp ngữ cảnh cho câu, Giúp bạn liên kết từ vựng với ngữ cảnh thực tế.</p>
                        <img src={imgBrain} alt="ADV" style={{ width: '100%' }} />
                        <h3>AI Cá Nhân<br></br>
                            Hóa Bài Tập</h3>
                        <p>Tự động tạo bài tập phù hợp với
                            từ vựng và lĩnh vực bạn chọn.</p>
                    </Block>

                    {/* Hearing Section */}
                    <Block className="main-features">
                        <img src={imgMicvoice} alt="ADV" style={{ width: '100%' }} />
                        <h3>Luyện nghe <br></br>
                            và nói phản xạ</h3>
                        <p style={{ color: '#fff' }}>Giúp bạn phát âm chuẩn và nói tự nhiên.</p>
                        <img src={imgBook} alt="ADV" style={{ width: '100%' }} />
                        <h3>Kho câu <br></br>
                            không giới hạn</h3>
                        <p style={{ color: '#fff', marginBottom: '2rem' }}>Phù hợp cho mọi trình độ và mục tiêu học.</p>
                        <div className='swap-btn'><Link className='btn-grey-big'>Nâng cấp ngay</Link></div>
                    </Block>

                    {/* faq Section */}
                    <Block className="funtion">
                        <h3>Câu Hỏi <br></br>
                            Thường Gặp</h3>
                        <img src={imgFaq} alt="ADV" style={{ width: '100%' }} />
                        <ul className='faq'>
                            <li className='quest'>Câu Hỏi 1:<br></br>
                                Tôi đã học tiếng Anh nhiều năm nhưng không
                                nói được, Wordbank có phù hợp không? </li>
                            <li className='answer'>
                                Hoàn toàn phù hợp! Wordbank giúp bạn biến lý
                                thuyết thành kỹ năng giao tiếp thực tế.</li>

                            <li className='quest'>Câu Hỏi 2: <br></br>
                                Tôi có thể luyện tập ở bất kỳ lĩnh vực nào không?</li>
                            <li className='answer'>
                                Có! Bạn chỉ cần nhập từ vựng, AI sẽ tạo bài tập
                                phù hợp cho lĩnh vực bạn chọn.</li>


                            <li className='quest'>Câu Hỏi 3: <br></br>
                                Mất bao lâu để thấy hiệu quả? </li>
                            <li className='answer'>
                                Với 15-30 phút luyện tập mỗi ngày, bạn sẽ thấy rõ
                                sự tiến bộ sau 21 ngày.</li>
                        </ul>
                    </Block>

                    {/* Footer Section */}
                    <Block className='curve'></Block>
                    <Block className="curved-background">
                        <div className="content">
                            <img src={imgWhitelogo} alt="logo" style={{ width: '200px' }} />
                            <p>Try the new way to learn language absolutely free</p>
                            <div className='swap-btn' style={{ marginTop: '3rem', marginBottom: '3rem' }}><Link className='btn-orange-big'>Nâng cấp ngay</Link></div>
                            <footer>
                                <Link>Wordbank 2024</Link>
                                <Link>Privacy policy</Link>
                                <Link>Terms of use</Link>
                            </footer>
                        </div>
                    </Block>
                </div>
            )}
        </Page>
    );
};

export default RegisterLandingPage;
