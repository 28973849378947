import React, { useState } from 'react';
import {
    Page,
    Block,
    List,
    ListInput,
    Navbar,
    NavLeft,
    NavRight,
    f7,
    Link,
} from 'framework7-react';
import myLogo from '../img/Wordbank.svg';
import store from '../store';


const ForgotPassword = ({ f7router }) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');

    const login = () => {
        store.dispatch('loginUser', {
            user: { email: username, password: password }, f7router, onLoginCompleted: (err) => {
                setLoginError(err)
            }
        })
    }
    const register = () => {
        store.dispatch('registerUser', {
            user: { email: username, password: password }, f7router, onLoginCompleted: (err) => {
                setLoginError(err)
            }
        })
    }
    return (
        <Page className="forgot-password" >
            <Navbar className='tran-head'>
                <NavLeft>                   
                    <Link onClick={() => f7.views.main.router.back()}>
                        <div className="heart">                            
                            <span className="heart-count">Back</span>
                        </div>
                    </Link>
                </NavLeft>
                <NavRight>
                    <Link className="get-likes-button">
                    <i className="f7-icons" style={{ fontSize: '24px', color: 'white' }}>globe</i>
                    </Link>
                </NavRight>
            </Navbar>
            {/* Header Section */}
            <section className="header-section" style={{ height: '100vh' }}>
                <Block className="logo" style={{ height: '14vh' }}>
                    <img src={myLogo} alt="Logo" />
                </Block>

                <Block className="subtitle">
                    <h3>Forgot your password</h3>
                    <p style={{ textAlign: 'left' }}>We have sent the password to your email.<br></br>
                        If you do not see the email, please check your spam folder.</p>
                </Block>

                {/* Login Form Section */}

                <Block className="login-form-section">
                    <List strongIos insetIos>
                        <ListInput outline type="text" placeholder="Your email" clearButton value={username}
                            onInput={(e) => {
                                setUsername(e.target.value);
                            }}>
                            <i className="f7-icons" slot="media" style={{ fontSize: '22px', color: 'white' }}>envelope_fill</i>

                        </ListInput>
                    </List>
                    <Link style={{ width: '90%' }} className="module_login" onClick={login}>Login</Link>
                </Block>
                {/* Forgot Password */}


            </section>


        </Page>
    );
};

export default ForgotPassword;
