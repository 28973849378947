// src/components/CustomToolbar.jsx
import React, { useEffect, useState } from 'react';
import { Toolbar, Button } from 'framework7-react';
import MicrophonePulseEffect from './microphone-pulse';

const CustomToolbar = ({ disableAdvance, startListening, handleSpeech, loadQuiz, f7router, isListening, interRim, preQuiz }) => {
  const [isSpeaking, setIsSpeaking] = useState(false); // Trạng thái khi người dùng nói
useEffect(()=>{
  setIsSpeaking(true)
  setTimeout(() => {
  setIsSpeaking(false)
  }, 100);
},[interRim])
  const navigateToPhrasePractice = () => {
    f7router.navigate('/phrase-practice-ui/');
  };

  return (
    <div className='swap-bot'>
      <Toolbar bottom slot="fixed" position="bottom" className="bot-toolbar">
        <Button onClick={navigateToPhrasePractice}>
        <i className="ny icon-book-add" style={{ fontSize: '1.8rem' }} />  
        </Button>
        <Button disabled={disableAdvance < 1} onClick={handleSpeech}>
          <i className="ny icon-speaker" style={{ fontSize: '1.8rem' }} />
        </Button>       
        <Button
          disabled={disableAdvance < 2}
          onClick={startListening}
          className={`main-btn ${isListening ? 'listening' : ''} ${isSpeaking ? 'speaking' : ''}`}
        >
          <i className="ny icon-miccro" style={{ fontSize: '2rem' }} />
        </Button>
        <Button disabled={disableAdvance < 1} onClick={preQuiz}>
          <i className="ny icon-next-arrow" style={{ fontSize: '1.8rem', transform:'rotate(180deg)' }} />
        </Button>
        <Button disabled={disableAdvance < 2} onClick={loadQuiz}>
          <i className="ny icon-next-arrow" style={{ fontSize: '1.8rem' }} />
        </Button>
      </Toolbar>

      {/* Component dùng để phát hiện âm thanh */}
      
    </div>
  );
};

export default CustomToolbar;
