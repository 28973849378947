import React, { useState } from 'react';
import {
  Page,
  Navbar,
  Block,
  Button,
  Link,
  NavLeft,
  NavRight,
  Card,
  Segmented,
} from 'framework7-react';
import Select from 'react-select';
import myEthe from '../img/ethe.png';

const WithDrawHistoryPage = ({ f7router }) => {
  const [activeTab, setActiveTab] = useState('history');

  const handleSegmentedButtonClick = (tab) => setActiveTab(tab);

  const options = [
    {
      value: 'Ethereum',
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={myEthe} alt="Ethereum" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
          Ethereum
        </div>
      ),
    },
    {
      value: 'BTC',
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={myEthe} alt="BTC" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
          BTC
        </div>
      ),
    },
  ];

  const customStyles = {
    control: (base) => ({
      ...base,
      minWidth: '160px',
      border: 'none',
      borderRadius: '8px',
      padding: '5px',
      boxShadow: 'none',
      '&:hover': { borderColor: '#666' },
    }),
    menu: (base) => ({
      ...base,
      borderRadius: '8px',
      marginTop: '0',
    }),
    option: (base, { isFocused }) => ({
      ...base,
      backgroundColor: isFocused ? '#eee' : 'white',
      color: '#333',
      padding: '10px',
    }),
  };

  const customComponents = {
    IndicatorSeparator: () => null,
  };

  //data table

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const optionss = [

    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
  ];

  const customStyless = {
    control: (base) => ({
      ...base,
      minWidth: '30px',
      fontSize: '14px',
    }),
    menu: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    option: (base, { isFocused }) => ({
      ...base,
      backgroundColor: isFocused ? '#eee' : 'white',
      color: '#333',
      padding: '10px',
    }),
    container: (provided) => ({
      ...provided,
      width: '100px',
    }),
  };

  const data = Array.from({ length: 40 }, (_, index) => ({
    stt: index + 1,
    loaiGiaoDich: index % 4 === 0 ? 'Nạp' : index % 4 === 1 ? 'Bán' : index % 4 === 2 ? 'Rút' : 'Quà',
    soLuong: `${Math.floor(Math.random() * 500) + 20}$`,
    trangThai:
      index % 3 === 0
        ? 'Success'
        : index % 3 === 1
          ? 'Pending'
          : 'Failed',
    thoiGianGiaoDich: '12/1/2024',
    chiTietGiaoDich: '<i className="ny icon-more-dot-icon" />',
  }));


  const totalPages = Math.ceil(data.length / rowsPerPage);

  const currentData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (direction) => {
    if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (direction === 'next' && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleRowsPerPageChange = (selectedOption) => {
    setRowsPerPage(selectedOption.value);
    setCurrentPage(1);
  };

  return (
    <Page className="top-up-page with-draw-history">
      <Navbar className="header-orange">
        <NavLeft>
          <Link onClick={() => f7router.back()}>
            <i className="ny icon-back-icon" />
            Lịch sử rút tiền
          </Link>
        </NavLeft>
        <NavRight>
          <Link className="get-info-btn">
            <span className="get-money">100$</span>
            <i className="ny icon-hand-get-icon" style={{ fontSize: '1.4rem' }} />
          </Link>
        </NavRight>
      </Navbar>

      <Block className="order-block">
        <Block className="tabs-header">
          <Segmented strong tag="p">
            <Button active={activeTab === 'history'} onClick={() => handleSegmentedButtonClick('history')}>
              Lịch sử
            </Button>
            <Button active={activeTab === 'account'} onClick={() => handleSegmentedButtonClick('account')}>
              Tài khoản
            </Button>
          </Segmented>
        </Block>
        <div className='content-segment'>
          {/* History Content */}
          {activeTab === 'history' && (
            <div className='content-segment'>
              <Card className="data-table" style={{ overflow: 'hidden' }}>
                <div style={{ overflowY: 'auto', maxHeight: '74vh' }}>
                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead style={{ position: 'sticky', top: 0, background: '#f7f7f7', zIndex: 999 }}>
                      <tr>
                        <th style={{ padding: '10px', textAlign: 'left', width: '10%' }}>Stt</th>
                        <th style={{ padding: '10px', textAlign: 'center', width: '20%' }}>Loại<br></br> giao dịch</th>
                        <th style={{ padding: '10px', textAlign: 'right', width: '20%' }}>Số lượng</th>
                        <th style={{ padding: '10px', textAlign: 'center', width: '20%' }}>Trạng thái</th>
                        <th style={{ padding: '10px', textAlign: 'right', width: '20%' }}>Thời gian<br />giao dịch</th>
                        <th style={{ padding: '10px', textAlign: 'right', width: '10%' }}>Chi tiết<br />giao dịch</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData.map((row) => (
                        <tr key={row.stt}>
                          <td style={{ padding: '10px', width: '10%', background: "#D9D9D9" }}>{row.stt}</td>
                          <td style={{ padding: '10px', textAlign: 'center', width: '20%' }}>{row.loaiGiaoDich}</td>
                          <td style={{ padding: '10px', textAlign: 'right', width: '20%' }}>{row.soLuong}</td>
                          <td
                            style={{
                              padding: '10px',
                              textAlign: 'center',
                              width: '20%',
                              color: row.trangThai === 'Success' ? 'green' : row.trangThai === 'Pending' ? 'orange' : 'red',
                            }}
                          >
                            {row.trangThai}
                          </td>
                          <td style={{ padding: '10px', textAlign: 'center', width: '20%' }}>{row.thoiGianGiaoDich}</td>
                          <td style={{ padding: '10px', textAlign: 'center', width: '10%' }}>
                            <i className="ny icon-more-dot-icon" />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '20px',
                  marginTop: '10px',
                }}
              >
                <div>
                  <Select
                    id="rows-per-page"
                    options={optionss}
                    value={optionss.find((option) => option.value === rowsPerPage)} // Đồng bộ giá trị hiện tại
                    onChange={handleRowsPerPageChange}
                    menuPlacement="top"
                    styles={customStyless}
                  />
                </div>

                <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center' }}>
                  <span style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center' }}>
                    Trang {currentPage} / {totalPages}
                  </span>
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <Link
                      onClick={() => handlePageChange('prev')}
                      className={`button ${currentPage === 1 ? 'disabled' : ''}`}
                    >
                      <i className="ny icon-back-caret-icon" />

                    </Link>
                    <Link
                      onClick={() => handlePageChange('next')}
                      className={`button ${currentPage === totalPages ? 'disabled' : ''}`}
                    >
                      <i style={{ transform: 'rotate(180deg)' }} className="ny icon-back-caret-icon" />

                    </Link>
                  </div>
                </div>


              </div>

            </div>
          )}

          {/* Bank Content */}
          {activeTab === 'account' && (
            <div className='content-segment'>
              <Block className='swap-ct'>
                <div className="header-topup">Rút tiền</div>
                <Block style={{ zIndex: '9' }} className="register-form-section">

                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <tbody>
                      <tr>
                        <td style={{ width: '50%', padding: '10px 0', verticalAlign: 'middle', color: '#666', fontWeight: '500' }}>
                          Tên ngân hàng
                        </td>
                        <td style={{ width: '50%', padding: '10px 0' }}>
                     
                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', width: '100%' }}>
                            <input
                              type="text"
                              placeholder="Nhập tên ngân hàng"
                              style={{
                                width: '100%',
                                borderRadius: "5px",
                                borderStyle: "solid",
                                borderColor: "rgba(174, 171, 171, 0.26)",
                                borderWidth: "1px",
                                padding: '5px 35px 5px 5px', // Thêm padding phải để icon không đè lên text
                                boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
                              }}
                            />
                            <i
                              className="ny icon-pen-edit-icon"
                              style={{
                                position: 'absolute',
                                right: '10px', // Canh icon bên phải
                                cursor: 'pointer',
                                color: '#000', // Màu icon
                              }}
                              onClick={() => console.log('Icon clicked')}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: '50%', padding: '10px 0', verticalAlign: 'middle', color: '#666', fontWeight: '500' }}>
                          Tên chủ tài khoản
                        </td>
                        <td style={{ width: '50%', padding: '10px 0' }}>
                  
                          <div style={{ position: 'relative', display: 'flex', alignItems: 'center', width: '100%' }}>
                            <input
                              type="text"
                              placeholder="Nhập tên chủ tài khoản"
                              style={{
                                width: '100%',
                                borderRadius: "5px",
                                borderStyle: "solid",
                                borderColor: "rgba(174, 171, 171, 0.26)",
                                borderWidth: "1px",
                                padding: '5px 35px 5px 5px', // Thêm padding phải để icon không đè lên text
                                boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
                              }}
                            />
                            <i
                              className="ny icon-pen-edit-icon"
                              style={{
                                position: 'absolute',
                                right: '10px', // Canh icon bên phải
                                cursor: 'pointer',
                                color: '#000', // Màu icon
                              }}
                              onClick={() => console.log('Icon clicked')}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: '50%', padding: '10px 0', verticalAlign: 'middle', color: '#666', fontWeight: '500' }}>
                          Số tài khoản ngân hàng
                        </td>
                        <td style={{ width: '50%', padding: '10px 0' }}>
                          <div style={{ position: 'relative', display: 'flex', alignItems: 'center', width: '100%' }}>
                            <input
                              type="text"
                              placeholder="Nhập số tài khoản"
                              style={{
                                width: '100%',
                                borderRadius: "5px",
                                borderStyle: "solid",
                                borderColor: "rgba(174, 171, 171, 0.26)",
                                borderWidth: "1px",
                                padding: '5px 35px 5px 5px', // Thêm padding phải để icon không đè lên text
                                boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
                              }}
                            />
                            <i
                              className="ny icon-pen-edit-icon"
                              style={{
                                position: 'absolute',
                                right: '10px', // Canh icon bên phải
                                cursor: 'pointer',
                                color: '#000', // Màu icon
                              }}
                              onClick={() => console.log('Icon clicked')}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Block>
                <Block className="back-home-button-block">
                  <Link className="back-home-button">Lưu thông tin tài khoản</Link>
                </Block>

              </Block>


            </div>

          )}
        </div>
      </Block>
    </Page>
  );
};

export default WithDrawHistoryPage;
